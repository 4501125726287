<template>
  <div class="vue-admin-beautiful-wrapper" :class="classObj">
    <div
      v-if="'horizontal' === layout"
      class="layout-container-horizontal"
      :class="{
        fixed: header === 'fixed',
        'no-tabs-bar': tabsBar === 'false' || tabsBar === false,
      }"
    >
      <div :class="header === 'fixed' ? 'fixed-header' : ''">
        <vab-top-bar />
        <div
          v-if="tabsBar === 'true' || tabsBar === true"
          :class="{ 'tag-view-show': tabsBar }"
        >
          <div class="vab-main">
            <vab-tabs-bar />
          </div>
        </div>
      </div>
      <div class="vab-main main-padding">
        <vab-ad />
        <vab-app-main />
      </div>
    </div>
    <div
      v-else
      class="layout-container-vertical"
      :class="{
        fixed: header === 'fixed',
        'no-tabs-bar': tabsBar === 'false' || tabsBar === false,
      }"
    >
      <div
        v-if="device === 'mobile' && collapse === false"
        class="mask"
        @click="handleFoldSideBar"
      />
      <!-- 左菜单 -->
      <vab-side-bar />
      <div class="vab-main" :class="collapse ? 'is-collapse-main' : ''">
        <div :class="header === 'fixed' ? 'fixed-header' : ''">
          <vab-nav-bar></vab-nav-bar>
          <vab-tabs-bar v-if="tabsBar === 'true' || tabsBar === true" />
        </div>
        <vab-ad />
        <vab-app-main />
      </div>
    </div>
    <el-backtop />
    <el-dialog title="切换企业" :visible.sync="showSwitchTab" width="40%">
      <el-form label-width="80px" :model="switchCompanyForm">
        <el-radio-group v-model="switchCompanyForm.company_id">
          <el-form-item v-for="item in companyList" :key="item.id">
            <el-radio :label="item.id">{{ item.name }}</el-radio>
          </el-form-item>
        </el-radio-group>
        <el-form-item>
          <el-button type="primary" @click="handleSwitchCompany">
            提交
          </el-button>
          <el-button @click="showSwitchTab = false">取消</el-button>
        </el-form-item>
      </el-form>
    </el-dialog>
    <!-- 切换后台 -->
    <el-dialog title="选择后台" :visible.sync="showBackstage" width="40%">
      <!-- {{ backstageList }} -->
      <div class="card-box">
        <a
          v-for="(item, index) in backstageList"
          :key="index"
          class="card-box-in"
          @click="handleSwitchBackstage(item)"
        >
          <div class="card-box-in-img">
            <el-image
              style="width: 100px; height: 100px"
              :src="item.image_url"
              lazy
            ></el-image>
          </div>

          <div class="card-box-in-name">{{ item.name }}</div>
          <div v-if="Number(backstageId) === item.id" class="card-box-cur">
            目前
          </div>
        </a>
      </div>
    </el-dialog>
  </div>
</template>

<script>
  import { mapActions, mapGetters, mapState } from 'vuex'
  import { tokenName } from '@/config'
  import { switchList, handleSwitch } from '@/api/company'
  import router from '../router'
  import { brand_shop_center, shop_shop_center } from '@/router/index.js'
  // import { backstageList } from '@/config/backstage.js'
  export default {
    name: 'Layout',
    provide() {
      return {
        showSwitchTab: this.handleShowSwitchTab,
        showBackstage: this.handleShowBackstage,
      }
    },
    data() {
      return {
        oldLayout: '',
        showSwitchTab: false,
        showBackstage: false, //切换后台对话框
        switchCompanyForm: {
          company_id: 0,
        },
        companyList: {},
        // backstageList: [], //后台系统数据
        backstageId: '', //当前后台系系统id
      }
    },

    computed: {
      ...mapGetters({
        layout: 'settings/layout',
        tabsBar: 'settings/tabsBar',
        collapse: 'settings/collapse',
        header: 'settings/header',
        device: 'settings/device',
        companyInfo: 'user/companyInfo',
      }),
      ...mapState({
        backstageList: (state) => state.routes.backstageList,
      }),
      classObj() {
        return {
          mobile: this.device === 'mobile',
        }
      },
    },
    created() {
      // console.info(this.$store, 'pppppppppppp')
      // this.backstageList = backstageList
      // 提前处理对话框标题
      this.backstageId = localStorage.getItem('backstage') || ''
    },
    beforeMount() {
      window.addEventListener('resize', this.handleResize)
    },
    beforeDestroy() {
      window.removeEventListener('resize', this.handleResize)
    },
    mounted() {
      this.oldLayout = this.layout
      const userAgent = navigator.userAgent
      if (userAgent.includes('Juejin')) {
        this.$baseAlert('不支持在掘金内置浏览器演示')
      }
      const isMobile = this.handleIsMobile()
      if (isMobile) {
        if (isMobile) {
          //横向布局时如果是手机端访问那么改成纵向版
          this.$store.dispatch('settings/changeLayout', 'vertical')
        } else {
          this.$store.dispatch('settings/changeLayout', this.oldLayout)
        }
        this.$store.dispatch('settings/toggleDevice', 'mobile')
        setTimeout(() => {
          this.$store.dispatch('settings/foldSideBar')
        }, 2000)
      } else {
        this.$store.dispatch('settings/openSideBar')
      }
      this.$nextTick(() => {
        window.addEventListener(
          'storage',
          (e) => {
            if (e.key === tokenName || e.key === null) window.location.reload()
            if (e.key === tokenName && e.value === null)
              window.location.reload()
          },
          false
        )
      })
    },
    methods: {
      ...mapActions({
        handleFoldSideBar: 'settings/foldSideBar',
      }),
      handleIsMobile() {
        return document.body.getBoundingClientRect().width - 1 < 992
      },
      handleResize() {
        if (!document.hidden) {
          const isMobile = this.handleIsMobile()
          if (isMobile) {
            //横向布局时如果是手机端访问那么改成纵向版
            this.$store.dispatch('settings/changeLayout', 'vertical')
          } else {
            this.$store.dispatch('settings/changeLayout', this.oldLayout)
          }

          this.$store.dispatch(
            'settings/toggleDevice',
            isMobile ? 'mobile' : 'desktop'
          )
        }
      },
      handleShowSwitchTab() {
        this.showSwitchTab = true
        // 获取企业列表
        switchList().then((data) => {
          this.companyList = data
          // 设置默认的企业id
          this.switchCompanyForm.company_id = this.companyInfo['id']
        })
      },
      handleShowBackstage() {
        this.showBackstage = true
      },
      // 切换后台
      async handleSwitchBackstage(item) {
        // 若选择当前的后台直接关闭
        // if (Number(this.backstageId) === item.id) {
        //   this.$message({
        //     message: '当前后台与选择后台无变化',
        //     type: 'warning',
        //     duration: 1200,
        //     onClose: () => {
        //       this.showBackstage = false
        //     },
        //   })
        //   return
        // }
        localStorage.setItem('backstage', item.id)
        const switchapp = await this.$store.dispatch(
          'routes/v2switchMyapplication',
          { app_id: item.id }
        )
        console.info(switchapp, '切换后台更新token')
        location.href = '/'
        setTimeout(async () => {
          await this.$store.dispatch('user/getUserInfo')
          this.showBackstage = false
          // 重新加载页面
        }, 800)
        // location.reload()
      },
      handleHideSwitchTab() {
        this.showSwitchTab = false
      },
      handleSwitchCompany() {
        // 如果选择的企业id 跟 当前企业id 一样，就不做操作
        if (this.switchCompanyForm.company_id == this.companyInfo['id']) {
          this.handleHideSwitchTab()
          return
        }

        handleSwitch(this.switchCompanyForm).then(async (res) => {
          await this.$store
            .dispatch('user/setUserInfo', res)
            .then(async () => {
              this.handleHideSwitchTab()
              // const routerPath =
              //   this.redirect === '/404' || this.redirect === '/401'
              //     ? '/'
              //     : this.redirect

              // TODO routerPath路由过滤会去掉部分路由，导致切换门店报404，现在默认重定向默认path
              this.$router.push({ path: '/' }).catch(() => {})
              this.loading = false
              // 重新加载页面
              location.reload()
              // this.$forceUpdate()
            })
            .catch(() => {
              this.loading = false
            })
        })
      },
    },
  }
</script>

<style lang="scss" scoped>
  @mixin fix-header {
    position: fixed;
    top: 0;
    right: 0;
    left: 0;
    z-index: $base-z-index - 2;
    width: 100%;
    overflow: hidden;
  }

  .card-box {
    display: flex;
    flex-wrap: wrap;
    .card-box-in {
      border: 4px solid #fff;
      width: 200px;
      height: 300px;
      margin-right: 20px;
      margin-bottom: 20px;
      background: #f7f9fa;
      border-radius: 12px;
      box-shadow: 0 2px 16px 0 rgb(29 54 83 / 20%);
      box-sizing: border-box;
      position: relative;
      overflow: hidden;
      .card-box-cur {
        position: absolute;
        top: 0px;
        right: 0px;
        // border-left: 3px solid #268bff;
        // border-bottom: 3px solid #268bff;
        width: 50px;
        height: 50px;
        line-height: 50px;
        text-align: center;
        color: #268bff;
        font-weight: 700;
        font-size: 16px;
        border-radius: 0 0 0 12px;
        box-shadow: 0 2px 16px 0 rgb(29 54 83 / 20%);
      }
      .card-box-in-img {
        height: 70%;
        display: flex;
        justify-content: center;
        align-items: center;
      }
      .card-box-in-name {
        padding-top: 20px;
        text-align: center;
        color: #1f2026;
        font-size: 16px;
      }
    }
    .card-box-in:hover {
      border: 4px solid #268bff;
      transform: translateY(-10px) scale(1.01);
      transition: all 0.4s ease-in;
    }
  }

  .vue-admin-beautiful-wrapper {
    position: relative;
    width: 100%;
    height: 100%;

    .layout-container-horizontal {
      position: relative;

      &.fixed {
        padding-top: calc(#{$base-top-bar-height} + #{$base-tabs-bar-height});
      }

      &.fixed.no-tabs-bar {
        padding-top: $base-top-bar-height;
      }

      ::v-deep {
        .vab-main {
          width: 88%;
          margin: auto;
        }

        .fixed-header {
          @include fix-header;
        }

        .tag-view-show {
          background: $base-color-white;
          box-shadow: $base-box-shadow;
        }

        .nav-bar-container {
          .fold-unfold {
            display: none;
          }
        }

        .main-padding {
          .app-main-container {
            margin-top: $base-padding;
            margin-bottom: $base-padding;
            background: $base-color-white;
          }
        }
      }
    }

    .layout-container-vertical {
      position: relative;

      .mask {
        position: fixed;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        z-index: $base-z-index - 1;
        width: 100%;
        height: 100vh;
        overflow: hidden;
        background: #000;
        opacity: 0.5;
      }

      &.fixed {
        padding-top: calc(#{$base-nav-bar-height} + #{$base-tabs-bar-height});
      }

      &.fixed.no-tabs-bar {
        padding-top: $base-nav-bar-height;
      }

      .vab-main {
        position: relative;
        min-height: 100%;
        margin-left: $base-left-menu-width;
        background: #f6f8f9;
        transition: $base-transition;

        ::v-deep {
          .fixed-header {
            @include fix-header;

            left: $base-left-menu-width;
            width: $base-right-content-width;
            box-shadow: $base-box-shadow;
            transition: $base-transition;
          }

          .nav-bar-container {
            position: relative;
            box-sizing: border-box;
          }

          .tabs-bar-container {
            box-sizing: border-box;
          }

          .app-main-container {
            width: calc(100% - #{$base-padding});
            margin: $base-padding auto;
            background: $base-color-white;
            border-radius: $base-border-radius;
          }
        }

        &.is-collapse-main {
          margin-left: $base-left-menu-width-min;

          ::v-deep {
            .fixed-header {
              left: $base-left-menu-width-min;
              width: calc(100% - 65px);
            }
          }
        }
      }
    }

    /* 手机端开始 */
    &.mobile {
      ::v-deep {
        .el-pager,
        .el-pagination__jump {
          display: none;
        }

        .layout-container-vertical {
          .el-scrollbar.side-bar-container.is-collapse {
            width: 0;
          }

          .vab-main {
            width: 100%;
            margin-left: 0;
          }
        }

        .vab-main {
          .fixed-header {
            left: 0 !important;
            width: 100% !important;
          }
        }
      }
    }

    /* 手机端结束 */
  }
</style>
